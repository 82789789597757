import * as React from 'react';
import Button from '@mui/material/Button';
import ArrowRight from '@mui/icons-material/ArrowRight';


export default class NextTermButton extends React.Component {
    
    render() {
        const {term, terms} = this.props;

        var index = terms.findIndex((element) => element.id === term.id);

        if (index + 1 >= terms.length) { return null };
        const next = terms[index+1];
        return (
           <Button sx={{pr:.5}} variant="outlined" href={"/term/" + next.slug}>Next<ArrowRight /></Button>
        );
    };
}






