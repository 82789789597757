import * as React from 'react';
import Typography from '@mui/material/Typography';
import RelatedTerms from './RelatedTerms'



export  class TermPrintVersion extends React.Component {
    render() {
        const { term } = this.props;
        if (term.title == null) { return null };

        return (
            <div>
                <Typography render="h3" sx={{pt: 1}}>
                    {term.title.rendered}
                </Typography>
                
                <div className="bodyContent">
                    <span dangerouslySetInnerHTML={{ __html: term.content.rendered }} />
                </div>
            </div>
        );
    };
}

export default class TermContent extends React.Component {
    render() {
        const { term,header } = this.props;
        var headerClass="";
        if(header===false){headerClass="printOnly"};
        if (term.title == null) { return null };

        return (

            <div>
                <Typography className={headerClass} component="h3" variant="h3" sx={{pt: .5}}>
                    {term.title}
                </Typography>

                <div className="bodyContent">
                    <span dangerouslySetInnerHTML={{ __html: term.content }} />
                </div>
                <RelatedTerms relatedterms={term.headstart.relatedterms} />
            </div>
        );
    };
}
