import * as React from 'react';

import Button from '@mui/material/Button';
import ArrowLeft from '@mui/icons-material/ArrowLeft';


export default class PreviousTermButton extends React.Component {
    
    render() {
        const {term, terms} = this.props;

        var index = terms.findIndex((element) => element.id === term.id);

        if (index <= 0) { return null };
        const prev = terms[index-1];
        return (
           <Button sx={{mr: 1,pl:0.5}} variant="outlined" href={"/term/" + prev.slug}><ArrowLeft/>Previous</Button>
        );
    };
}






