import React from "react"
import {  graphql } from "gatsby"
import PageLayout from '../components/PageLayout.js';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TermList from '../components/TermListB.js';
import TermContent from '../components/TermContent.js';
import Heading from '../components/Heading';
import TwoColumnLayout from '../components/TwoColumnLayout';
import PreviousTermButton from '../components/PreviousTermButton'
import NextTermButton from '../components/NextTermButton'
import Seo from "../components/Seo"

const Term = ({ data }) => {
    const term = data.page;
    const category = term.categories.nodes.filter(x => x.slug !== "headstart")[0];

    const terms = category.contentNodes.nodes.sort(function(a, b) {
        var orderBool = a.title.toLowerCase() > b.title.toLowerCase();
        return orderBool ? 1 : -1;
      });
    //return category.description;
    const edit = false;

    return (
        <PageLayout>
          <Seo title={ term.title} />
            <Heading
                title={category.description}
                url={"/" + category.slug.replace("headstart-", "")}
                emailTitle={term.title}
                emailUrl={"/term/" + term.slug}
                postId={term.id}
                edit={edit} />

            <TwoColumnLayout>
                <>
                    <TermContent
                        term={term} />

                    <Box sx={{ pt: 1, pb: 2 }} className="noPrint">
                        <PreviousTermButton term={term} terms={terms} /><NextTermButton term={term} terms={terms} />
                    </Box>
                </>
                <div className="noPrint">
                    <Typography component="h4" variant="h4" >
                        Terms
                    </Typography>
                    <TermList terms={terms} currentTerm={term.id} />
                </div>
            </TwoColumnLayout>
        </PageLayout>
    );

}

export default Term

export const pageQuery = graphql`
query TermQuery($slug: String!) {
    page: wpPost(slug: {eq: $slug}) {
      id
      uri
      title
      content
      databaseId
      uri
      slug
      categories {
        nodes {
          databaseId
          slug
          description
          contentNodes {
            nodes {
              ... on WpPost {
                id
                slug
                title
              }
            }
          }
        }
      }
      headstart {
        relatedterms {
          ... on WpPost {
            id
            slug
            title
          }
        }
      }
    }
  }
  
`

