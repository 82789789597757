import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';

export default class RelatedTerms extends React.Component {
    render() {
      const {relatedterms} = this.props;
      if (relatedterms === null || relatedterms === undefined || relatedterms ==="") return null;
      return (
        <>
        <Typography component="h4" variant="h4">Related Terms</Typography><List dense disablePadding sx={{pb:2}}>
           {
           relatedterms.map((term) =>
              <ListItem disableGutters key={term.slug}><Link href={"/term/" + term.slug}>{term.title}</Link></ListItem>
           )}
          </List>
        </>
      );
    }
  }





